import React, { Component } from 'react'
import Image from "gatsby-image"
import './Profile.scss'

class Profile extends Component {

    render() {

        const { isPlaceholder, photo, name, title, bio, email, linkedin, isAdvisor } = this.props

        return <>
            {isAdvisor ? 
                <div className={"profile advisor"} id="profile-mobile">
                    <div className="name">{name}</div>
                    <div className="profile-icons advisor">
                        { linkedin ? <a href={linkedin} target="_blank" rel="noopener noreferrer"><i className="icon-linkedin"></i></a> : ''}
                        { email ? <a href={email} target="_blank" rel="noopener noreferrer"><i className="icon-mail"></i></a> : ''}
                    </div>
                    <span className="title">{title}</span>
                    <p>{bio}</p>
                </div>
            :
                <div className={"profile"} id="profile-mobile">
                    {photo && <Image
                        fluid={photo}
                        className={`${isPlaceholder ? 'placeholder' : ''}`}
                        alt="Customized solutions" />
                    }
                    <div className="name">{name}</div>
                    <span className="title">{title}</span>
                    <div className="row profile-icons">
                        { linkedin ? <a href={linkedin} target="_blank" rel="noopener noreferrer"><i className="icon-linkedin"></i></a> : ''}
                        { email ? <a href={email} target="_blank" rel="noopener noreferrer"><i className="icon-mail"></i></a> : ''}
                    </div>
                </div>}
            <div className="profile" id="profile-web">
                {photo &&
                    <Image
                        fluid={photo}
                        className={`${isPlaceholder ? 'placeholder' : ''}`}
                        alt={name} />
                }
                <div className="name">
                    {name}
                    <div className="pull-right">
                        { linkedin ? <a href={linkedin} target="_blank" rel="noopener noreferrer"><i className="icon-linkedin"></i></a> : ''}
                        { email ? <a href={email} target="_blank" rel="noopener noreferrer"><i className="icon-mail"></i></a> : ''}
                    </div>
                </div>
                <span className="title">{title}</span>
                {
                    bio ? <p>{bio}</p>
                    :
                    <p>We don't have any openings right now, but if you feel you can contribute in our mission to helping people achieve self-actualization,
                        we'll be happy to hear from you! <a href='mailto:hello@mercurics.com' target='_blank' rel='noopener noreferrer'>Get in touch</a> with us today!</p>
                }
            </div>
        </>
    }
}

export default Profile