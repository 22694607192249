import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import SEO from "../components/seo"
import Profile from "../components/Profile"
import Avatar from '../images/team/Avatar.png'
import './about.scss'


const About = () => {

    const data = useStaticQuery(graphql`
	query {
        brian: file(relativePath: { eq: "team/brian-yap.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 2000, maxHeight: 2667) {
				  ...GatsbyImageSharpFluid
				}
			  }
        },
        claire: file(relativePath: { eq: "team/claire-tan.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 2000, maxHeight: 2667) {
				  ...GatsbyImageSharpFluid
				}
			  }
        },
        kai: file(relativePath: { eq: "team/kai-reuber.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 2000, maxHeight: 2667) {
				  ...GatsbyImageSharpFluid
				}
			  }
		},
		ray: file(relativePath: { eq: "team/ray-tham.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 2000, maxHeight: 2667) {
				  ...GatsbyImageSharpFluid
				}
			  }
        },
        zhenyang: file(relativePath: { eq: "team/zhenyang.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 2000, maxHeight: 2667) {
				  ...GatsbyImageSharpFluid
				}
			  }
		},
	  }
	`)

    return (
        <div className="about-page">
            <SEO title="About" />
            {/* Content: Team */}

            <section className="bg--tertiary top-section" id="about">
                <div className="container content">
                    <div className="row">
                        <div className="col-sm-12">
                            <span className="text-gray-light">ABOUT MERCURICS</span>    
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-8" style={{paddingRight: '3rem'}}>
                            <h1 className="section-title-black">We help companies and individuals unlock the power of personality data to achieve business goals and self-actualization.</h1>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg--quaternary">
                <div className="container content">
                    <h1 className="section-title-secondary">Core Values</h1>
                    <div className="row">
                        <div className="core-values">
                            <h2>Inclusive</h2>
                            <p>Our solutions are designed to be inclusive for all, without discrimination.</p>
                        </div>
                        <div className="core-values">
                            <h2>Connectedness</h2>
                            <p>Democratize personality data for workplace and individual connectedness for better productivity and psychological well-being.</p>
                        </div>
                        <div className="core-values">
                            <h2>Respect</h2>
                            <p>We respect user data and privacy and build our solutions upon this foundation.</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default About